import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { auth } from '@/api';
import useResetGlobal from '@/store/zustand/useResetGlobal';
import { useQueryClient } from '@tanstack/react-query';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';
import rules from '../../helpers/rules';
import { displayOrCatch, useErrorState } from '../../helpers';
import PopoutForm from '../forms/PopoutForm';
import userSubscribedStatusLoading from '../../store/actions/userSubscribedStatusLoading';
import fetchSubscription from '../../helpers/fetchSubscription';

const onFinish = (
  userAuthenticated,
  userSubscribeStatusLoading,
  fetchUserSubscription,
  displayError,
  setLoading,
  resetStore,
  removeQueries,
) => (values) => {
  setLoading(true);
  auth
    .login(values)
    .then((response) => {
      resetStore();
      userSubscribeStatusLoading();
      userAuthenticated(response.data.data.user);
      fetchUserSubscription();
      removeQueries();
    })
    .catch((e) => {
      console.error(e);
      displayOrCatch(e, displayError);
      setLoading(false);
    });
};

const LoginForm = ({
  userAuthenticated,
  userSubscribeStatusLoading,
  fetchUserSubscription,
}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();
  const { resetStore } = useResetGlobal();

  return (
    <PopoutForm
      className="!mt-6 !space-y-3 !px-4 md:!px-0"
      popoutError={error}
      onFinish={onFinish(
        userAuthenticated,
        userSubscribeStatusLoading,
        fetchUserSubscription,
        setError,
        setLoading,
        resetStore,
        queryClient.removeQueries(),
      )}
    >
      <Form.Item className="!m-0" name="email" rules={rules.loginEmail}>
        <Input
          size="large"
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item className="!m-0" name="password" rules={rules.loginPassword}>
        <Input.Password
          size="large"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item className="!m-0">
        <Button
          loading={loading}
          htmlType="submit"
          size="large"
          type="primary"
          className="w-full"
        >
          Login
        </Button>
      </Form.Item>
    </PopoutForm>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
  userSubscribeStatusLoading: () => dispatch(userSubscribedStatusLoading()),
  fetchUserSubscription: () => fetchSubscription(dispatch),
});

export default connect(null, mapDispatchToProps)(LoginForm);
