import axios from '@/api/axios';
import auth from '@/api/auth';

const crawler = (domains) => axios.post('/api/apify/webscan',
  { team_id: auth.team().id, urls: domains })
  .then((data) => data)
  .catch((e) => Promise.reject(e));

const status = (actorRunId) => axios.get('/api/apify/status', {
  params: {
    team_id: auth.team().id,
    actor_run_id: actorRunId,
  },
})
  .then(({ data }) => data.status)
  .catch((e) => Promise.reject(e));

const getList = (platform) => axios.get('/api/apify/list', {
  params: {
    team_id: auth.team().id,
    platform,
  },
})
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const twitter = (hashtags, handles) => axios.post('/api/apify/twitter', {
  team_id: auth.team().id,
  hashtags,
  handles,
})
  .then((data) => data)
  .catch((e) => Promise.reject(e));

const reddit = (domains) => axios.post('/api/apify/reddit',
  { team_id: auth.team().id, urls: domains })
  .then((data) => data)
  .catch((e) => Promise.reject(e));

const apify = {
  crawler,
  status,
  twitter,
  reddit,
  getList,
};

export default apify;
