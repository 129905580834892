export const SET_CONNECTED_PLATFORMS = 'integrations/SET_CONNECTED_PLATFORMS';
export const SET_CSV_FILES = 'integrations/SET_CSV_FILES';
export const SET_HIGHLIGHTED_PLATFORM = 'integrations/SET_HIGHLIGHTED_PLATFORM';
export const SET_HIGHLIGHTED_CUSTOM_COMPONENT = 'integrations/SET_HIGHLIGHTED_CUSTOM_COMPONENT';
export const SET_CSV_UPLOADED = 'integrations/SET_CSV_UPLOADED';
export const SET_RECONNECT_PLATFORM = 'integrations/SET_RECONNECT_PLATFORM';
export const SET_CRAWLERS_LIST = 'integrations/SET_CRAWLERS_LIST';
export const SET_TWITTER_LIST = 'integrations/SET_TWITTER_LIST';
export const SET_REDDIT_LIST = 'integrations/SET_REDDIT_LIST';

export const initialState = {
  connectedPlatforms: [],
  csvFiles: [],
  highlightedPlatform: null,
  highlightedCustomComponent: null,
  csvUploaded: false,
};

export const integrationsReducer = (prevState, action) => {
  switch (action.type) {
    case SET_RECONNECT_PLATFORM: {
      return {
        ...prevState,
        requestedReconnect: action.payload,
        connectedPlatforms: prevState.connectedPlatforms.filter((p) => p.type !== action.payload),
      };
    }
    case SET_CONNECTED_PLATFORMS: {
      return {
        ...prevState,
        requestedReconnect: action.clearReconnect ? null : prevState.requestedReconnect,
        connectedPlatforms: action.clearReconnect
          ? action.payload : action.payload.filter((p) => p.type !== prevState.requestedReconnect),
      };
    }
    case SET_CSV_FILES: {
      return {
        ...prevState,
        csvFiles: action.payload,
      };
    }
    case SET_HIGHLIGHTED_PLATFORM: {
      return {
        ...prevState,
        highlightedPlatform: action.payload,
      };
    }
    case SET_HIGHLIGHTED_CUSTOM_COMPONENT: {
      return {
        ...prevState,
        highlightedPlatform: 'custom',
        highlightedCustomComponent: action.payload,
      };
    }
    case SET_CSV_UPLOADED: {
      return {
        ...prevState,
        csvUploaded: action.payload,
      };
    }
    case SET_CRAWLERS_LIST: {
      return {
        ...prevState,
        crawlersList: action.payload,
      };
    }
    case SET_TWITTER_LIST: {
      return {
        ...prevState,
        twitterList: action.payload,
      };
    }
    case SET_REDDIT_LIST: {
      return {
        ...prevState,
        redditList: action.payload,
      };
    }
    default: {
      return prevState;
    }
  }
};
