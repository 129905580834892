/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Form, Input, Spin,
} from 'antd';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import apify from '@/api/apify';
import { useHistory } from 'react-router-dom';
import { CheckCircle2Icon } from 'lucide-react';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { cn } from '@/helpers/util';
import ValidatedForm from '../../../components/forms/ValidatedForm';
import useIntegrationsStore from '../store/useIntegrationsStore';

const CrawlerConnect = () => {
  const history = useHistory();
  const { reset } = useDefaultDateStore();
  const { actions, state } = useIntegrationsStore();

  const [form] = Form.useForm();
  const [actorRunId, setActorRunId] = useState(undefined);
  const [isDisabled, setIsDisabled] = useState(true);

  const { data: status } = useQuery({
    queryKey: ['crawler', actorRunId],
    queryFn: () => apify.status(actorRunId),
    refetchInterval: (response) => (response !== 'RUNNING' ? 0 : 5000),
    enabled: !!actorRunId,
  });

  const connect = useMutation({
    mutationKey: 'crawler',
    mutationFn: ({ domains }) => apify.crawler(domains),
    onSuccess: ({ data }) => {
      setActorRunId(data.actor_run_id);
      actions.updateCrawlersList();
    },
  });

  useEffect(() => {
    apify.getList('webscan');
  }, []);

  const handleFieldsChange = (_, allFields) => {
    const values = form.getFieldValue('domains') || [];
    const hasErrors = allFields.some((field) => field.errors.length > 0);
    const hasValidDomain = values.some((domain) => domain?.trim());
    setIsDisabled(hasErrors || !hasValidDomain);
  };

  return (
    <div className="crawler_connect">
      {
        status === 'SUCCEEDED'
          ? (
            <div className="text-center space-y-3 mt-6">
              <CheckCircle2Icon className="fill-green-600 stroke-green-900" size={32} />
              <p className="mb-lg">Success! Your data has been collected.</p>
              <Button
                type="link"
                onClick={() => {
                  reset();
                  history.push('/discovery');
                }}
                className="!text-green-900 !mt-lg text-brand"
              >
                View data
              </Button>
            </div>
          )
          : status === 'RUNNING'
            ? (
              <div className="text-center">
                <p>Scanning your web pages</p>
                <Spin className="mt-lg" />
              </div>
            )
            : (
              <ValidatedForm
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={connect.mutate}
                onFieldsChange={handleFieldsChange}
              >
                <Form.List name="domains">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          label={index === 0 ? 'Domains' : ''}
                          required
                          key={field.key}
                          style={{ marginBottom: index === 0 ? 0 : 25 }}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please add a domain or delete this field.',
                              },
                              {
                                type: 'url',
                                message: 'Please enter a valid website URL (e.g., https://example.com)',
                              },
                            ]}
                            noStyle
                          >
                            <Input placeholder="Domain" style={{ width: '94%' }} size="large" autoComplete="off" />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => {
                                remove(field.name);
                                setTimeout(() => {
                                  handleFieldsChange([], form.getFieldsError());
                                }, 0);
                              }}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        {fields.length < 5 ? (
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              setTimeout(() => {
                                handleFieldsChange([], form.getFieldsError());
                              }, 0);
                            }}
                            style={{ width: '94%', marginTop: 15 }}
                            icon={<PlusOutlined />}
                          >
                            Add domain
                          </Button>
                        ) : null }
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" disabled={isDisabled || connect.isLoading}>
                    Start
                  </Button>
                </Form.Item>
              </ValidatedForm>
            )
      }
      {
        state.crawlersList !== undefined && state.crawlersList.length !== 0 && (
        <>
          <Divider type="horizontal" />
          <CrawlersConnected />
        </>
        )
      }
    </div>
  );
};

const WebpageRow = ({ webpage }) => (
  <>
    {
    webpage.urls.map((page, key) => (
      <li className={cn('text-[#595959] border-0 border-b border-[#E8E8E8] border-solid last:border-none flex justify-between font-[Gordita-Medium]', 'p-2.5 bg-[#F6F9FB]')} key={key}>
        <span>{page}</span>
        {webpage.status === 'RUNNING' && (
        <span className="text-[#595959]">
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        </span>
        )}
      </li>
    ))
    }
  </>
);

function ScannedWebpages({ webpages }) {
  return (
    <ul className="mt-3 list-none p-0 border rounded-md border-solid border-[#E8E8E8] max-w-md">
      {webpages?.map((webpage) => (
        <WebpageRow key={webpage.id} webpage={webpage} />
      ))}
    </ul>
  );
}

function CrawlersConnected() {
  const { state } = useIntegrationsStore();

  return (
    <>
      <p className="text-[var(--grey)]">
        Your scanned webpages
      </p>
      <ScannedWebpages webpages={state.crawlersList} />
    </>
  );
}

export default CrawlerConnect;
