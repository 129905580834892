/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Form, Select,
  Spin,
} from 'antd';
import { CheckCircle2Icon } from 'lucide-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import apify from '@/api/apify';
import { cn } from '@/helpers/util';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import ValidatedForm from '../../../components/forms/ValidatedForm';
import useIntegrationsStore from '../store/useIntegrationsStore';

const TwitterConnect = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { reset } = useDefaultDateStore();
  const { actions, state } = useIntegrationsStore();

  const [handles, setHandles] = useState(undefined);
  const [hashtags, setHashtags] = useState(undefined);
  const [actorRunId, setActorRunId] = useState(undefined);
  const [error, setError] = useState(null);

  const isDisabled = (handles === undefined || handles.length === 0) && (hashtags === undefined || hashtags.length === 0);

  const { data: status } = useQuery({
    queryKey: ['twitter', actorRunId],
    queryFn: () => apify.status(actorRunId),
    refetchInterval: (response) => (response !== 'RUNNING' ? 0 : 5000),
    enabled: !!actorRunId,
  });

  const connect = useMutation({
    mutationKey: 'twitter',
    mutationFn: () => apify.twitter(hashtags, handles),
    onSuccess: ({ data }) => {
      setActorRunId(data.actor_run_id);
      actions.updateTwitterList();
    },
  });

  useEffect(() => {
    apify.getList('twitter');
  }, []);

  const validateHashtags = (values) => {
    setHashtags(values);
    if (!values || values.every((value) => value.startsWith('#'))) {
      setError(null);
      return true;
    }
    setError('Each hashtag must start with #');
    return false;
  };

  const handleChange = (values) => {
    validateHashtags(values);
    form.setFieldsValue({ hashtags: values });
  };

  return (
    <div className="twitter_connect">
      {
        status === 'RUNNING' ? (
          <div className="text-center">
            <p>Scanning your twitt</p>
            <Spin className="mt-lg" />
          </div>
        ) : status === 'SUCCEEDED' ? (
          <div className="text-center">
            <CheckCircle2Icon className="fill-green-600 stroke-green-900" size={32} />
            <p className="mb-lg">Success! Your data has been collected.</p>
            <Button
              type="link"
              onClick={() => {
                reset();
                history.push('/discovery');
              }}
              className="!text-green-900 !mt-lg text-brand"
            >
              View data
            </Button>
          </div>
        ) : (
          <ValidatedForm
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={connect.mutate}
          >
            <Form.Item key={0}>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Handles"
                onChange={(values) => setHandles(values)}
                options={[]}
              />
            </Form.Item>
            <Form.Item
              key={1}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  validator: validateHashtags,
                },
              ]}
              validateStatus={error ? 'error' : ''}
              help={error}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Hashtags"
                onChange={handleChange}
                options={[]}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large" disabled={isDisabled || connect.isLoading || !!error}>
                Start
              </Button>
            </Form.Item>
          </ValidatedForm>
        )
      }
      {
        state.twitterList !== undefined && state.twitterList.length !== 0 && (
        <>
          <Divider type="horizontal" />
          <TwitterConnected />
        </>
        )
      }
    </div>
  );
};

const TwitterRow = ({ twitt }) => (
  <>
    {
        twitt.handles.map((handle, key) => (
          <li className={cn('text-[#595959] border-0 border-b border-[#E8E8E8] border-solid last:border-none flex justify-between font-[Gordita-Medium]', 'p-2.5 bg-[#F6F9FB]')} key={key}>
            <span>{handle}</span>
            {twitt.status === 'RUNNING' && (
              <span className="text-[#595959]">
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              </span>
            )}
          </li>
        ))
      }
    {
        twitt.hashtags.map((hashtag, key) => (
          <li className={cn('text-[#595959] border-0 border-b border-[#E8E8E8] border-solid last:border-none flex justify-between font-[Gordita-Medium]', 'p-2.5 bg-[#F6F9FB]')} key={key}>
            <span>{hashtag}</span>
            {twitt.status === 'RUNNING' && (
              <span className="text-[#595959]">
                <Spin indicator={<LoadingOutlined spin />} size="small" />
              </span>
            )}
          </li>
        ))
      }
  </>
);

function ScannedTwitterPosts({ twitts }) {
  return (
    <ul className="mt-3 list-none p-0 border rounded-md border-solid border-[#E8E8E8] max-w-md">
      {twitts?.map((twitt) => (
        <TwitterRow key={twitt.id} twitt={twitt} />
      ))}
    </ul>
  );
}

function TwitterConnected() {
  const { state } = useIntegrationsStore();

  return (
    <>
      <p className="text-[var(--grey)]">
        Your scanned twitts
      </p>
      <ScannedTwitterPosts twitts={state.twitterList} />
    </>
  );
}

export default TwitterConnect;
