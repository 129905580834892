export const INTEGRATION_TYPE = Object.freeze({
  INTERCOM: 'intercom',
  CHAT_FEEDBACK_UPLOAD: 'chat_feedback_upload',
  FEEDBACK_UPLOAD: 'feedback_upload',
  SURVEY_UPLOAD: 'survey_upload',
  REVIEW_UPLOAD: 'review_upload',
  ZENDESK_SUPPORT: 'zendesk',
  TRUSTPILOT: 'trustpilot',
  TYPEFORM: 'typeform',
  DRIFT: 'drift',
  FRESHDESK: 'freshdesk',
  QUALAROO: 'qualaroo',
  REVIEWS_IO: 'reviews_io',
  G2: 'g2',
  SURVICATE: 'survicate',
  CAPTERRA: 'capterra',
  FRONT: 'front',
  SLACK: 'slack',
  HUBSPOT_CHAT: 'hubspot_chat',
  ZENDESK_CHAT: 'zendesk_chat',
  GET_FEEDBACK: 'get_feedback',
  ZAPIER: 'zapier',
  GORGIAS: 'gorgias',
  CRAWLER: 'webscan',
  TWITTER: 'twitter',
  REDDIT: 'reddit',
});
