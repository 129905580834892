import Layout from '@/components/Layout';
import { TaxonomyContainer } from '@/components/taxonomy';
import useTaxonomy from '@/components/taxonomy/hooks/useTaxonomy';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import ReleaseEditModal from '@/components/bucket/ReleaseEditModal';
import { useModalStore } from '@/store/zustand/useModalStore';
import { FiltersProvider } from '@/components/filter/context';
import EditModal from '@/components/bucket/EditModal';
import { useHistory } from 'react-router-dom';
import { useSearchStore } from '@/store/zustand/useSearchStore';
import CreateDropdown from '@/components/taxonomy/components/CreateDropdown';
import CategoryModal from '@/components/categories/CategoryModal';
import AiModal from '@/components/ai/Modal';
import { Settings2Icon, Star } from 'lucide-react';
import EditTaxonomyDrawer from '@/components/taxonomy/components/EditTaxonomyDrawer';
import { TaxonomyTableProvider } from '@/components/taxonomy/store/Provider';
import { useFavoritesStore } from '@/store/zustand/useFavoritesStore';
import { Search } from '@/components/taxonomy/components/Search';
import { CreateTopicModal } from '@/components/bucket/CreateTopicModal';

export function TaxonomyPage() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { defaultDate } = useDefaultDateStore();
  const {
    payload, type, setModal, reset,
  } = useModalStore();
  const { setSearch } = useSearchStore();
  const taxonomy = useTaxonomy({ start: defaultDate.start, end: defaultDate.end });

  const [openDrawer, setOpenDrawer] = useState(false);
  const { favorites, setFavorites } = useFavoritesStore();

  /** Hide intercom when drawer is opened */
  useEffect(() => {
    if (!openDrawer) {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
      return;
    }

    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }, [openDrawer]);

  function handleOk() {
    queryClient.invalidateQueries({ queryKey: ['topic_graph'] });
  }

  const topicModalProps = {
    visible: type === 'create_taxonomy_topic',
    onCancel: () => reset(),
    onOk: (topic) => {
      reset();
      history.push(`${history.location.pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${topic.id}/${topic.groups[0].id}`);
    },
  };

  const categoryModalProps = {
    visible: type === 'create_taxonomy_category',
    onCancel: () => reset(),
    onOk: () => {
      reset();
      queryClient.invalidateQueries({ queryKey: ['taxonomy'] });
    },
  };

  const aiTopicModalProps = {
    visible: type === 'create_taxonomy_ai_topic',
    onCancel: () => reset(),
    onOk: () => {
      reset();
      queryClient.invalidateQueries({ queryKey: ['taxonomy'] });
    },
  };

  // Clear search on unmount
  useEffect(() => () => setSearch(''), []);

  if (taxonomy.isLoading) {
    return (
      <Layout
        title="Taxonomy"
        disableMantra
        rightHeaderClasses="items-center"
        rightHeader={(
          <>
            <CreateDropdown />
            <SentimentDateFilter />
          </>
    )}>
        <div className="text-center p-6">
          <Spin />
        </div>
      </Layout>
    );
  }

  return (
    <TaxonomyTableProvider>
      <Layout
        title="Taxonomy"
        disableMantra
        rightHeaderClasses="items-center"
        leftHeader={<Search />}
        rightHeader={(
          <>
            <Button
              className="flex items-center justify-center"
              icon={<Star fill={favorites ? '#44596C' : 'transparent'} size={16} />}
              type="secondary"
              onClick={() => setFavorites()}
            />
            <CreateDropdown />
            <Button
              className="flex gap-2.5 items-center"
              type="secondary"
              onClick={() => setOpenDrawer(true)}
            >
              <Settings2Icon
                size={16}
              />
              <span>Edit Taxonomy</span>
            </Button>
          </>
    )}
      >
        <div className="text-right mx-6">
          <SentimentDateFilter />
        </div>
        <TaxonomyContainer />
        <TaxonomyTableProvider>
          <EditTaxonomyDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        </TaxonomyTableProvider>

        <FiltersProvider>
          <CreateTopicModal {...topicModalProps} />
        </FiltersProvider>

        <CategoryModal {...categoryModalProps} />
        <AiModal {...aiTopicModalProps} />

        {
        type === 'edit_modal' ? (
          <FiltersProvider value={{ filters: payload?.filters?.actual, selectedFilters: payload?.filters?.applied }}>
            <EditModal visible onCancel={() => setModal(null)} />
          </FiltersProvider>
        ) : null
      }
        <ReleaseEditModal visible={type === 'edit_releases_modal'} onOk={handleOk} onCancel={() => setModal(null)} />
      </Layout>
    </TaxonomyTableProvider>
  );
}
