import React, { useRef, useState, useEffect } from 'react';
import {
  Button, Menu, Dropdown, Empty, Spin, message, Select,
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  Blocks, Plug, Tag, Smile, WholeWord, ListFilter, ArrowLeft, ChartNoAxesCombined, ListCollapse, ArrowDown01,
} from 'lucide-react';
import { KeywordFilter } from '@/components/filter/filters/KeywordFilter';
import { useFilters, useFiltersDispatch } from '@/components/filter/context';
import './AddFilterButton.styles.less';
import { getSentimentIcon, sentimentsList } from '@/components/discovery/utils/sentiment';
import { useVisibleFilterDropdownStore } from '@/store/zustand/useVisibleFilterDropdownStore';
import useCustomFields from '@/components/discovery/hooks/useCustomFields';
import { showLabel } from '@/components/filter/filters/CustomFieldFilter';
import { uniqueId } from 'lodash';
import useSources from '@/data/useSources';
import useLabels from '@/components/discovery/hooks/useLabels';
import CustomFieldItem from '@/components/bucket/CustomFieldItem';
import SourceItem from '@/components/bucket/SourceItem';
import { cn } from '@/helpers/util';
import { auth } from '@/api';
import { hasNps } from '@/helpers/features';
import { npsClassifiers } from '@/components/bucket/lib/utils';

const getNameFrom = (type) => {
  switch (type) {
    case 'keywords': return 'Keywords';
    case 'sentiment': return 'Sentiment';
    case 'custom_fields': return 'Custom field';
    case 'sources': return 'Source';
    case 'labels': return 'Label';
    case 'nps': return 'NPS';
    default: return type;
  }
};

const MenuWrapper = ({
  type, setType, children, extra,
}) => (
  <div className={cn('add-filter-wrapper bg-white rounded-lg py-2 px-2 border border-solid border-[#CEDBE4]', type === 'keywords' && 'filter-keywords-wrap')}>
    <div className="flex flex-col">
      <div className="flex text-[#202324] items-center px-2">
        <Button className="add-filter-back-button" type="link" onClick={() => setType(null)}>
          <ArrowLeft size={18} />
        </Button>
        <span>{getNameFrom(type)}</span>
        {extra}
      </div>
      <div>
        {children}
      </div>
    </div>
  </div>
);

const MenuKeywords = ({ type, setType, filters }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <MenuWrapper type={type} setType={setType}>
      <div className="text-[#44596C] font-[Gordita-Medium] text-sm mt-4 px-2">
        <span>Keywords to include</span>
        <KeywordFilter.Select type="included_keywords" defaultValue={filters?.included_keywords} />
      </div>
      <div className="text-[#44596C] font-[Gordita-Regular] text-xs mt-4 px-2">
        <Button className="add-filter-keywords-button" type="link" onClick={() => setCollapsed((prev) => !prev)}>
          {collapsed ? <DownOutlined /> : <UpOutlined />}
          Keywords to exclude
        </Button>
        {
          !collapsed ? (
            <KeywordFilter.Select type="excluded_keywords" defaultValue={filters?.excluded_keywords} />
          ) : null
        }
      </div>
    </MenuWrapper>
  );
};

const MenuSentiment = ({ type, setType, filters }) => {
  const [list, setList] = useState(filters?.sentiment.map((value) => value?.type ?? value) || []);
  const { setVisible } = useVisibleFilterDropdownStore((state) => state);
  const dispatch = useFiltersDispatch();
  const handleClick = (sentiment) => {
    if (list.includes(sentiment)) {
      setList((prevState) => prevState.filter((existing) => existing !== sentiment));
      dispatch({ type: 'REMOVE_FILTERS_FIELD', field: 'sentiment', value: sentiment });
      setVisible(false);
      setType(null);
    } else {
      setList((prevState) => [...prevState, sentiment]);
      dispatch({ type: 'ADD_FILTERS_FIELD', field: 'sentiment', value: sentiment });
      setVisible(false);
      setType(null);
    }
  };

  return (
    <MenuWrapper type={type} setType={setType}>
      <Menu className="sentiment-menu">
        {Object.values(sentimentsList).map((zen, index) => (
          <Menu.Item
            className="flex items-center gap-2 hover:!bg-[#F6F9FB] !px-3 !rounded-[4px] !mb-0 !mt-0"
            key={zen.label}
            onClick={() => handleClick(Object.keys(sentimentsList)[index])}
          >
            {getSentimentIcon(zen.label.toLowerCase())}
            {zen.label}
          </Menu.Item>
        ))}
      </Menu>
    </MenuWrapper>
  );
};

// eslint-disable-next-line no-unused-vars
const MenuCustomField = ({ type, setType, filters }) => {
  const { data, isLoading } = useCustomFields({ added: true });
  const dispatch = useFiltersDispatch();
  const [list, setList] = useState([]);

  const handleMenuClick = (field, value) => {
    const newField = {
      id: field.key,
      name: field.name,
      data_type: field.data_type,
      values: [showLabel(value)],
    };

    if (list.find((item) => item.id === field.key)) {
      setList((prevState) => prevState.map((item) => {
        if (item.id !== field.key) return item;
        if (item.values.includes(showLabel(value))) {
          message.error('Value already selected');
          return item;
        }

        return {
          ...item,
          values: [...item.values, showLabel(value)],
        };
      }));

      dispatch({
        type: 'SET_FILTERS_FIELD',
        field: 'custom_fields',
        value: filters.custom_fields.map((item) => {
          if (item.id !== field.key) return item;
          if (item.values.includes(showLabel(value))) {
            return item;
          }
          return {
            ...item,
            values: [...item.values, showLabel(value)],
          };
        }),
      });

      return;
    }

    // doesn't exist in list
    setList((prevState) => [...prevState, newField]);
    dispatch({ type: 'ADD_FILTERS_FIELD', field: 'custom_fields', value: newField });
  };

  const content = data?.length > 0 ? data.map((item) => (
    <CustomFieldItem key={item.key} item={item} handleMenuClick={handleMenuClick} />
  )) : (
    <div className="pt-5 mx-auto">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No custom fields added" />
    </div>
  );

  return (
    <MenuWrapper type={type} setType={setType}>
      <Menu className="custom-fields-menu new-add-filter">
        {isLoading ? (
          <div className="p-5 flex items-center justify-center">
            <Spin />
          </div>
        ) : content}
      </Menu>
    </MenuWrapper>
  );
};

const MenuSource = ({ type: menuType, setType }) => {
  const [list, setList] = useState([]);
  const dispatch = useFiltersDispatch();
  const {
    data, isLoading,
  } = useSources();
  const { setVisible } = useVisibleFilterDropdownStore((state) => state);

  const handleClick = (item, type) => {
    if (list.some((existing) => existing.id === item.id)) {
      setList((prev) => prev.filter((existing) => existing.id !== item.id));
      dispatch({ type: 'SET_FILTERS_FIELD', field: 'sources', value: list.filter((existing) => existing.id !== item.id).map((existing) => ({ source_id: existing.id, type: existing.type })) });
      setVisible(false);
    } else {
      setList((prev) => [...prev, { ...item, type }]);
      dispatch({ type: 'ADD_FILTERS_FIELD', field: 'sources', value: { source_id: item.id, type } });
      setVisible(false);
    }
  };

  const content = data && Object.keys(data).length > 0 ? Object.keys(data)
    .filter((platform) => data[platform].filter((item) => item.conversations_count > 0).length > 0)
    .map((platform) => (
      <SourceItem
        key={`${uniqueId('platform')}`}
        sources={data}
        source={platform}
        handleClick={handleClick}
      />
    ))
    : null;

  return (
    <MenuWrapper type={menuType} setType={setType}>
      <Menu className="source-menu">
        {isLoading ? (
          <div className="text-center m-8">
            <Spin size="medium" />
          </div>
        ) : content}
      </Menu>
    </MenuWrapper>
  );
};

const MenuLabel = ({ type: menuType, setType }) => {
  const {
    data: labels, isLoading, isError, error,
  } = useLabels();
  const [labelType, setLabelType] = useState('include');
  const { setVisible } = useVisibleFilterDropdownStore((state) => state);

  const [list, setList] = useState([]);
  const dispatch = useFiltersDispatch();

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  const handleClick = (label) => {
    if (list.includes(label)) {
      setList((prevState) => prevState.filter((existing) => existing !== label));
      dispatch({ type: 'REMOVE_FILTERS_FIELD', field: labelType === 'include' ? 'labels' : 'exclude_labels', value: label.id });
      setVisible(false);
    } else {
      setList((prevState) => [...prevState, label]);
      dispatch({ type: 'REMOVE_FILTERS_FIELD', field: labelType !== 'include' ? 'labels' : 'exclude_labels', value: label.id });
      dispatch({ type: 'ADD_FILTERS_FIELD', field: labelType === 'include' ? 'labels' : 'exclude_labels', value: label.id });
      setVisible(false);
    }
  };

  const content = labels?.length > 0 ? labels.map((label) => (
    <Menu.Item
      className="flex items-center gap-2 hover:!bg-[#F6F9FB] !px-3 !rounded-[4px] !mb-0 !mt-0"
      key={label.id}
      onClick={() => handleClick(label)}
    >
      <div className="rounded-full w-2 h-2 " style={{ backgroundColor: label.color }} />
      <span>
        {label.name}
      </span>
    </Menu.Item>
  )) : (
    <div className="pt-5 mx-auto">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No labels" />
    </div>
  );

  const typeExtra = (
    <span className="flex grow flex-row-reverse">
      <Select onSelect={setLabelType} className="label-select-type" defaultValue={labelType} size="small">
        <Select.Option value="include">INCLUDE</Select.Option>
        <Select.Option value="exclude">EXCLUDE</Select.Option>
      </Select>
    </span>
  );

  return (
    <MenuWrapper type={menuType} setType={setType} extra={typeExtra}>
      <Menu className="label-menu">
        {isLoading ? (
          <div className="p-5 flex items-center justify-center">
            <Spin />
          </div>
        ) : content}
      </Menu>
    </MenuWrapper>
  );
};

const MenuNps = ({ type: menuType, setType }) => {
  const [listClassification, setListClassification] = useState([]);
  const [listScore, setListScore] = useState([]);
  const dispatch = useFiltersDispatch();
  const { setVisible } = useVisibleFilterDropdownStore((state) => state);

  const handleClick = (item, type) => {
    if (type === 'nps_classification') {
      if (listClassification.some((existing) => existing.name === item)) {
        setListClassification((prev) => prev.filter((existing) => existing.name !== item));
        dispatch({
          type: 'SET_FILTERS_FIELD',
          field: 'nps',
          value: listClassification.filter((existing) => existing.name !== item)
            .map((existing) => ({ name: existing.name, type })),
        });
        setVisible(false);
      } else {
        setListClassification((prev) => [...prev, { ...item, type }]);
        dispatch({ type: 'ADD_FILTERS_FIELD', field: 'nps', value: { name: item, type } });
        setVisible(false);
      }
    }
    if (type === 'nps_score') {
      if (listScore.some((existing) => existing.name === item)) {
        setListScore((prev) => prev.filter((existing) => existing.name !== item));
        dispatch({
          type: 'SET_FILTERS_FIELD',
          field: 'nps',
          value: listScore.filter((existing) => existing.name !== item)
            .map((existing) => ({ name: existing.name, type })),
        });
        setVisible(false);
      } else {
        setListScore((prev) => [...prev, { ...item, type }]);
        dispatch({ type: 'ADD_FILTERS_FIELD', field: 'nps', value: { name: item, type } });
        setVisible(false);
      }
    }
  };

  return (
    <MenuWrapper type={menuType} setType={setType}>
      <Menu className="nps-menu">
        <Menu.SubMenu
          popupOffset={[2, -12]}
          className="source-filter-menu-item [&>div]:flex [&>div]:items-center min-w-[10rem] [&_.ant-dropdown-menu-submenu-title]:!text-[#202324]"
          popupClassName="!shadow-[0px_1px_12px_#CAD7E180]"
          title="Classification"
          icon={<ListCollapse width={16} strokeWidth={2} className="mr-1" />}
        >
          {npsClassifiers.map((item) => (
            <Menu.Item
              onClick={() => handleClick(item.name, 'nps_classification')}
              key={item.id}
              className="space-x-1 !text-[#44596C] hover:bg-[#EFF4F8] !mx-1 rounded-[4px]"
            >
              <span>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</span>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.SubMenu
          popupOffset={[2, -12]}
          className="[&>div]:flex [&>div]:items-center min-w-[10rem] [&_.ant-dropdown-menu-submenu-title]:!text-[#202324]"
          popupClassName="max-h-[300px] overflow-y-scroll !shadow-[0px_1px_12px_#CAD7E180]"
          title="Score"
          icon={<ArrowDown01 width={16} strokeWidth={2} className="mr-1" />}
        >
          {Array.from({ length: 11 }, (_, i) => i).map((score) => (
            <Menu.Item
              onClick={() => handleClick(score, 'nps_score')}
              key={score}
              className="space-x-1 !text-[#44596C] hover:bg-[#EFF4F8] !mx-1 rounded-[4px]"
            >
              <span>{score}</span>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    </MenuWrapper>
  );
};

const MenuWithType = () => {
  const { type, setType } = useVisibleFilterDropdownStore((state) => state);
  const { filters } = useFilters();
  const hasNpsActive = hasNps(auth.me());

  if (type === 'keywords') {
    return (
      <MenuKeywords setType={setType} type={type} filters={filters} />
    );
  }

  if (type === 'sentiment') {
    return (
      <MenuSentiment setType={setType} type={type} filters={filters} />
    );
  }

  if (type === 'custom_fields') {
    return (
      <MenuCustomField setType={setType} type={type} filters={filters} />
    );
  }

  if (type === 'sources') {
    return (
      <MenuSource setType={setType} type={type} filters={filters} />
    );
  }

  if (type === 'labels') {
    return (
      <MenuLabel setType={setType} type={type} filters={filters} />
    );
  }

  if (type === 'nps' && hasNpsActive) {
    return (
      <MenuNps setType={setType} type={type} filters={filters} />
    );
  }

  return (
    <Menu className="add-filter-main">
      <Menu.Item onClick={() => setType('keywords')}>
        <Button type="link">
          <WholeWord size={20} strokeWidth={2} />
          <span>Keywords</span>
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => setType('sentiment')}>
        <Button type="link">
          <Smile size={20} strokeWidth={2} />
          <span>Sentiment</span>
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => setType('custom_fields')}>
        <Button type="link">
          <Blocks size={20} strokeWidth={2} />
          <span>Custom field</span>
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => setType('sources')}>
        <Button type="link">
          <Plug size={20} strokeWidth={2} />
          <span>Source</span>
        </Button>
      </Menu.Item>
      <Menu.Item onClick={() => setType('labels')}>
        <Button type="link">
          <Tag size={20} strokeWidth={2} />
          <span>Label</span>
        </Button>
      </Menu.Item>
      { hasNpsActive && (
      <Menu.Item onClick={() => setType('nps')}>
        <Button type="link">
          <ChartNoAxesCombined size={20} strokeWidth={2} />
          <span>NPS</span>
        </Button>
      </Menu.Item>
      )}
    </Menu>
  );
};

const overlay = (
  <MenuWithType />
);

const AddFilterButton = () => {
  const buttonRef = useRef(null);
  const { visible, setVisible } = useVisibleFilterDropdownStore((state) => state);

  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target) && !(event.target.closest('div.add-filter-wrapper') || event.target.closest('div.ant-select-dropdown') || event.target.closest('div.ant-dropdown') || event.target.closest('div.ant-picker-dropdown') || event.target.closest('div.ant-menu-submenu'))) {
        setVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef]);

  return (
    <Dropdown overlay={overlay} visible={visible} trigger="click">
      <Button className="add-filter-main-button ant-btn ant-btn-text ant-dropdown-trigger flex items-center gap-1.5 !border-[#CFDBE4] !rounded-md !shadow-md !shadow-[#d8e2e980] !text-[#75899B] !px-3 hover:!border-[#A9BCCF] hover:!bg-[#EFF4F8] hover:!text-[#44596C] disabled:!bg-[#F6F9FB] disabled:!text-[#CFDBE4] disabled:!border-[#DFE8F1]" ref={buttonRef} type="secondary" onClick={() => setVisible(!visible)}>
        <span className="anticon"><ListFilter size={16} /></span>
        Add filter
      </Button>
    </Dropdown>
  );
};

export default AddFilterButton;
